<template>
  <!--begin::SubscriptionTypes index-->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("SUBSCRIPTION_TYPES.TITLE_INDEX") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box pt-9">
      <div v-if="ItemsSearching" class="text-primary">
        {{ $t("GENERIC.SEARCHING") }}...
      </div>

      <div v-if="!ItemsSearching && ItemsList.length < 1" class="text-danger">
        {{ $t("GENERIC.NO_RESULTS") }}
      </div>

      <div
        v-for="(item, index) in ItemsList"
        :key="index"
        class="row py-6 white-table border-box shadow-box mb-6"
      >
        <!--begin::Details-->
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-8 text-dark">
              <h5>
                <strong>
                  {{
                    $t("COMPANY_TYPE_NAMES_BY_ID." + item.CompanyTypeID) +
                      " - " +
                      item.Name
                  }}
                </strong>
              </h5>
            </div>
            <div class="col-lg-4 text-primary">
              <h4
                v-html="
                  formatSubscriptionPrice(item.PricePerMonth, item.Months)
                "
              ></h4>
            </div>
            <div class="col-lg-12" v-if="item.Description.length > 1">
              {{ item.Description[0] }}
            </div>
          </div>
        </div>
        <!--end::Details-->

        <!--begin::Legend-->
        <div class="col-lg-2">
          <div v-if="item.IsDefault" class="mb-3">
            <p
              class="white-box border-2 p-3 font-size-sm text-uppercase"
              style="white-space: nowrap"
            >
              {{ $t("SUBSCRIPTION_TYPES.IS_DEFAULT") }}
            </p>
          </div>
          <p
            class="white-box border-2 p-3 font-size-sm text-uppercase"
            style="white-space: nowrap"
          >
            {{ $t("COMPANY_TYPE_NAMES_BY_ID." + item.CompanyTypeID) }}
          </p>
        </div>
        <!--end::Legend-->

        <!--begin::Actions-->
        <div class="col-lg-1 pt-3">
          <span
            class="action-icon"
            @click="
              $router.push(
                '/manager/subscriptions/types/edit/' + item.SubscriptionTypeID
              )
            "
          >
            <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
          </span>
        </div>
        <!--end::Actions-->
      </div>

      <div class="row mt-12 mb-6">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>
      </div>
    </div>
  </div>
  <!--end::SubscriptionTypes index-->
</template>

<script>
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "SubscriptionTypesIndex",
  data() {
    return {
      ItemsList: [],
      ItemsSearching: true
    };
  },

  mounted() {
    this.listAllSubscriptionTypes();
  },

  methods: {
    listAllSubscriptionTypes() {
      this.ItemsSearching = true;
      this.ItemsList = [];

      SubscriptionTypeService.listAll(0).then(response => {
        this.ItemsList = response;
        this.ItemsSearching = false;
      });
    },

    formatSubscriptionPrice(pricePerMonth, months) {
      let price = parseFloat(pricePerMonth);

      if (price === 0) {
        return "<strong>" + this.$t("GENERIC.PRICE_FREE") + "</strong>";
      } else {
        price = price * months;
        return (
          "<strong>" +
          price.toFixed(2).replace(".", ",") +
          " €</strong> / " +
          this.$tc("SUBSCRIPTION_TYPES.PER_MONTHS", months, { count: months })
        );
      }
    }
  }
};
</script>
